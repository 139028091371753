import PropTypes from "prop-types"
import React from "react"

import Button from "@bit/azheng.joshua-tree.button";
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';

export default function ButtonGroupMap(props) {

    return (
        <ButtonGroup {...props}>
            {
                props.buttons.map((buttonObj) => {
                    let button = buttonObj.button;
                        return (
                            <Button 
                            contained={button.appearance === "contained"}
                            external={button.destination === "external"}
                            tel={button.destination === "tel"}
                            key={button.href} 
                            buttonText={button.buttonText} 
                            href={button.href}
                            />
                        )                        
                
                })
            }
        </ButtonGroup>
    )
}

ButtonGroupMap.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            button: PropTypes.shape({
                buttonText: PropTypes.string,
                href: PropTypes.string,
                youtube: PropTypes.string,
                appearance: PropTypes.string.isRequired,
                destination: PropTypes.string
              })
        })
    )
}