import { Link, navigateTo, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ImageMeta from "./ImageMeta"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

var classNames = require("classnames")

function ReviewRow(props) {
  return (
    <div className="review-row">
      <div className="columns review-row-heading">
        <div className="column is-6"></div>
        <div className="column mobile-row-heading" style={{ paddingLeft: "1.625rem" }}>
          <h6 style={{ display: "inline" }}>
            {props.name} Reviews {props.gender === "F" ? "Her" : "His"}{" "}
            {props.nameSingular} Procedure
          </h6>
          <Link
            className
            to={`/${props.url.toLowerCase()}/`}
            style={{ paddingLeft: "1.625rem", fontSize: "0.875rem" }}
          >
            Watch Review
          </Link>
        </div>
        <div className="column is-4"></div>
      </div>
      <div className="columns review-row-content">
        <div className="column is-4"></div>
        <div className="column is-2 review-photo-and-caption">
          <ImageMeta
            publicId={props.photo}
            cloudName="nuvolum"
            width="auto"
            responsive
            responsiveUseBreakpoints="true"
            className="review-row-photo"
          >
          <Transformation width="200" height="200" gravity="face" radius="max" crop="thumb" />
          </ImageMeta>
          <h6 className="image-caption staff">{props.name}</h6>
          <h6 className="image-caption staff staff-title light">
            {props.nameSingular}
            <br />
            Patient
          </h6>
        </div>
        <div className="column mobile-row-p" style={{ paddingLeft: "1.625rem" }}>
          <p>"{props.quote}"</p>
        </div>
        <div className="column is-4"></div>
      </div>
    </div>
  )
}

ReviewRow.propTypes = {
  minimal: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
}

export default ReviewRow
